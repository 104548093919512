import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { ArticleCard } from "@/components/ArticleCard/index"
import { getAllBlogsByCategory, getBlogsByCategory } from "@/utils/blog"
import useWindowResize from "@/hooks/useWindowResize"
import Pagination from "@/components/Blog/Pagination"
import PageLoader from "@/components/core/PageLoader/PageLoader"

const BlogCategory = ({ activeTab, categories }) => {
  const [blogs, setBlogs] = useState([])
  const [width] = useWindowResize(true)
  const [viewAll, setViewAll] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [paginationState, setPaginationState] = useState({
    rows: width >= 992 ? 9 : 5,
    start: 0,
  })
  const [loading, setLoading] = useState(false)
  const { rows, start } = paginationState
  const getBlogByCategoryData = async () => {
    setLoading(true)
    const category = categories.find(c => c.url === activeTab)?.categoryName
    if (category) {
      const {
        response: { docs = [], numFound = 0 },
      } = await getBlogsByCategory(category, paginationState)
      setLoading(false)
      const blogsData = docs.filter(item => item.url_s)
      setBlogs(blogsData)
      setTotalCount(numFound)
    }
  }
  const getAllBlogs = async () => {
    const {
      response: { docs = [] },
    } = await getAllBlogsByCategory(category, totalCount)
    setLoading(false)
    const blogsData = docs.filter(item => item.url_s)
    setBlogs(blogsData)
  }
  useEffect(() => {
    setPaginationState(state => ({ ...state, rows: width >= 992 ? 9 : 5 }))
  }, [width])
  useEffect(() => {
    if (viewAll) {
      getAllBlogs()
    }
  }, [viewAll])
  useEffect(() => {
    getBlogByCategoryData()
  }, [activeTab, start, rows])
  useEffect(() => {
    const itemsPerPage = width >= 992 ? 9 : 5
    const startIndex = (activePage - 1) * itemsPerPage
    setPaginationState(state => ({ ...state, start: startIndex }))
  }, [activePage])
  useEffect(() => {
    const itemPerPage = width >= 992 ? 9 : 5
    if (totalCount > 0) {
      setNumberOfPages(Math.ceil(totalCount / itemPerPage))
    }
  }, [totalCount])
  const category = categories.find(c => c.url === activeTab)?.categoryName
  return (
    <>
      <div className="blog-page__list-wrapper">
        <ul className="blog-page__list-content" id="blog-page__list-content">
          {blogs.map(item => {
            return (
              <li
                key={item.id}
                className={`blog-page__article-card-wrapper-list`}
                role="listitem"
              >
                <ArticleCard data={item} eyebrowText={category} />
              </li>
            )
          })}
        </ul>
        {!viewAll && blogs.length ? (
          <div className="blog-page__footer-div">
            <div
              className="blog-page__view-all"
              onClick={() => setViewAll(true)}
            >
              View All
            </div>
            <Pagination
              numberOfPages={numberOfPages}
              activePage={activePage}
              setActivePage={setActivePage}
              itemsPerPage={width >= 992 ? 9 : 5}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
export default BlogCategory
