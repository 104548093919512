import { pushAnalyticsObjToDataLayer } from "@/utils/helper"
const pageType = "lead generation"
const componentType = "lead generation"
export const submitLeadGenerationAnalytics = (status, statusMsg) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${componentType}:form:submit`,
    eventName: `${pageType}:${componentType}:form:submit`,
    eventStatus: status,
    eventMsg: statusMsg ?? "n/a",
    eventType: "navigation",
    internalLinkName: "submit",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:${componentType}:form`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const navigateToLeadGenerationAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `kohler-experience-center:book-an-appointment`,
    eventName: `kohler-experience-center:book-an-appointment`,
    eventStatus: "n/a",
    eventMsg: "n/a",
    eventType: "navigation",
    internalLinkName: "book-an-appointment",
    internalLinkPosition: `kohler-experience-center`,
    internalLinkType: `kohler-experience-center:navigation`,
    internalLinkZoneName: `kohler-experience-center`,
    internalLinkURL: "n/a",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
