import Button from "@/components/core/Button/Button"
import React, { useEffect, useState } from "react"
import Sticky from "react-sticky-el"
import styles from "@/components/LeadGeneration/Sticky.module.scss"
import useWindowResize from "@/hooks/useWindowResize"
const StickyHeader = props => {
  const {
    sticky,
    setSticky,
    navigateToBookAppointment = () => {},
    staticText = {},
  } = props
  const [scrollUp, setScrollUp] = useState(false)
  const [width] = useWindowResize(true)
  const [showText, setShowText] = useState(width >= 992)
  let lastScrollY = 0
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollUp(true)
      } else {
        setScrollUp(false)
      }
      lastScrollY = window.scrollY
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setShowText(width > 992)
  }, [width])

  return (
    <div className={styles.blogStickyHeadWrapper}>
      <Sticky
        className="blog-sticky-header"
        stickyClassName={"sticky sticky-blog-page"}
        onFixedToggle={() => setSticky(!sticky)}
      >
        {sticky && (
          <div className="blog-sticky-header container-fluid">
            <div className="blog-sticky-header__details-wrap container-fluid">
              <div className="blog-sticky-header__container kf-react-plp-container">
                {showText ? (
                  <div className="blog-sticky-header__details">
                    <span className="blog-sticky-header__brand-name">
                      {staticText.createDesignPlan}
                    </span>
                    <p className="blog-sticky-header__description">
                      {staticText.chooseAppointments}
                    </p>
                  </div>
                ) : null}

                <div
                  className={`blog-sticky-header__right ${
                    !showText ? "blog-sticky-header__align-center" : ""
                  }`}
                >
                  <Button
                    onClick={navigateToBookAppointment}
                    useDefaultClass={true}
                    className="gbh-data-layer"
                    role="button"
                    size="small"
                    type="primary"
                    label={staticText.bookAppointment}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Sticky>
    </div>
  )
}
export default StickyHeader
