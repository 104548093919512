import React, { useEffect, useState } from "react"
import cx from "classnames"
import { getBlogs } from "@/utils/blog"
import styles from "@/components/Blog/index.module.scss"
import { ArticleCard } from "@/components/ArticleCard/index"
import BlogCategory from "./BlogCategory"

const BlogLanding = props => {
  const {
    data: { categoryTab },
  } = props
  const [blogs, setBlogs] = useState([])
  const [categories, setCategories] = useState([])
  const [activeTab, setActiveTab] = useState(null)

  const getCategoryUrl = (category = "") =>
    "/blog/" + category.toLowerCase().replace(/\s+/g, "-")

  useEffect(() => {
    if (categoryTab?.length) {
      const tabData = []
      categoryTab.forEach(item => {
        tabData.push({ ...item, url: getCategoryUrl(item.categoryName) })
      })
      tabData.unshift({ categoryName: "All", url: "/blog" })
      setCategories(tabData)
    }
    if (window.location.pathname === "/blog") getBlogData()
    setActiveTab(window.location.pathname)
  }, [])

  useEffect(() => {
    if (blogs.length) {
      setTimeout(() => {
        const carousels = categories.map(
          c => `carousel-${c.categoryName.toLowerCase().replace(" ", "-")}`
        )
        carousels.forEach(cId => {
          const elem = document.getElementById(cId)
          if (elem) {
            window.OverlayScrollbars(elem, {
              className: "os-theme-dark",
              resize: "none",
              sizeAutoCapable: true,
              clipAlways: true,
              normalizeRTL: true,
              paddingAbsolute: false,
              autoUpdate: null,
              autoUpdateInterval: 33,
              nativeScrollbarsOverlaid: {
                showNativeScrollbars: false,
                initialize: true,
              },
              overflowBehavior: {
                y: "hidden",
              },
              scrollbars: {
                visibility: "auto",
                autoHide: "never",
                autoHideDelay: 800,
                dragScrolling: true,
                clickScrolling: true,
                touchSupport: true,
                snapHandle: true,
              },
            })
          }
        })
      }, 100)
    }
  }, [blogs])

  const getBlogData = async () => {
    const {
      response: { docs = [] },
    } = await getBlogs()
    const blogsData = docs.filter(item => item.url_s)
    setBlogs(blogsData)
  }

  const changeActive = item => {
    setActiveTab(item.url)
    window.history.replaceState(null, null, item.url)
    const element = document.getElementById("blog-page__tab-list")
    if (element) {
      window.scrollTo({ top: element.offsetTop - 50 })
    }
  }

  const categoryContent = category => {
    return (
      <div
        className="blog-page__content"
        id={`carousel-${category.toLowerCase().replace(" ", "-")}`}
      >
        <ul className="blog-page__slider" role="listbox">
          {blogs &&
            blogs
              .filter(e => e.category_ss?.[0] === category)
              .map(item => {
                return (
                  <li
                    key={item.id}
                    className={`blog-page__article-card-wrapper`}
                    role="listitem"
                  >
                    <ArticleCard data={item} eyebrowText={category} />
                  </li>
                )
              })}
        </ul>
      </div>
    )
  }

  const activeTabContent = url => {
    const category = categories.find(c => c.url === url)?.categoryName
    return (
      <div className="blog-page__list-wrapper">
        <ul className="blog-page__list-content" id="blog-page__list-content">
          {blogs &&
            blogs
              .filter(e => e.category_ss?.[0] === category)
              .map(item => {
                return (
                  <li
                    key={item.id}
                    className={`blog-page__article-card-wrapper-list`}
                    role="listitem"
                  >
                    <ArticleCard data={item} eyebrowText={category} />
                  </li>
                )
              })}
        </ul>
      </div>
    )
  }

  return (
    <div className={styles.blogLanding}>
      <ul
        id="blog-page__tab-list"
        className="blog-page__tab-list"
        role="tablist"
      >
        {categories?.map(item => (
          <li
            id={`${item.categoryName}-tab`}
            role="tab"
            aria-selected={activeTab === item.url}
            tabIndex={activeTab === item.url ? 0 : -1}
            className={cx({
              "blog-page__tab-list-item": true,
              "blog-page__tab-list-active": activeTab === item.url,
            })}
            key={item.url}
            onClick={() => changeActive(item)}
          >
            {item.categoryName}
          </li>
        ))}
      </ul>

      {activeTab === "/blog" ? (
        <>
          {categories.map(item => {
            if (
              item.categoryName.toLowerCase() !== "all" &&
              blogs.some(b => b.category_ss?.includes(item.categoryName))
            ) {
              return (
                <div
                  className="blog-page__carousel"
                  id="blog-page__carousel"
                  key={`carousel-${item.url.replace("/", "")}`}
                >
                  <h2 className="blog-page__heading">{item.categoryName}</h2>
                  {categoryContent(item.categoryName)}
                  <div
                    className="blog-page__viewAllLink"
                    onClick={() => changeActive(item)}
                  >
                    View All
                  </div>
                </div>
              )
            }
          })}
        </>
      ) : (
        <BlogCategory activeTab={activeTab} categories={categories} />
      )}
    </div>
  )
}

export default BlogLanding
