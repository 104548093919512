import { PLP_END_POINT_CLIENT, apiBaseUrl } from "@/constants/index"
import { apim } from "@/constants/api"

export const getBlogs = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(
        `${PLP_END_POINT_CLIENT}?q=*:*&rows=50&profilename=profile_kohler-india_PLP_Blogs&collections=kohler-india&fl=id,url_s,thumbNailImage_s,title_s,releaseDate_s,category_ss`
      )
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
export const getBlogsByCategory = async (category_ss, paginationState) => {
  const { start, rows } = paginationState
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(`${PLP_END_POINT_CLIENT}`, {
        params: {
          fl: "id,url_s,thumbNailImage_s,title_s,releaseDate_s,category_ss",
          q: "*:*",
          fq: `category_ss:("${category_ss}")`,
          collections: "kohler-india",
          profilename: `profile_kohler-india_PLP_Blogs`,
          rows: `${rows}`,
          start: `${start}`,
        },
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
export const getAllBlogsByCategory = async (category_ss, totalCount) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: content } = await apim.get(`${PLP_END_POINT_CLIENT}`, {
        params: {
          fl: "id,url_s,thumbNailImage_s,title_s,releaseDate_s,category_ss",
          q: "*:*",
          fq: `category_ss:("${category_ss}")`,
          collections: "kohler-india",
          profilename: `profile_kohler-india_PLP_Blogs`,
          rows: `${totalCount}`,
          start: 0,
        },
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}
