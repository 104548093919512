import { useEffect, useState, useRef } from "react"
import styles from "@/components/LeadGeneration/LeadGenerationView/index.module.scss"
import useLeadGeneration from "@/i18n/useLeadGenerationi18n"
import Confirmation from "@/components/LeadGeneration/Confirmation"
import Enquiry from "@/components/LeadGeneration/Form/Enquiry"
import StickyHeader from "../StickyHeader"
import useWindowResize from "@/hooks/useWindowResize"
import { navigateToLeadGenerationAnalytics } from "../analytics"

const LeadGenerationView = props => {
  const { data: authorData = {}, stateWithCities } = props
  const confirmationRef = useRef(null)
  const staticText = useLeadGeneration(authorData)
  const [confirmationSuccess, setConfirmationSuccess] = useState(null)
  const [sticky, setSticky] = useState(true)
  const [width] = useWindowResize(true)
  const divRef = useRef()
  const [isMobile, setIsMobile] = useState(!(width >= 992))

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setSticky(entry.boundingClientRect.top > 0 && !entry.isIntersecting)
    })
    if (divRef.current) observer.observe(divRef.current)
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [])
  useEffect(() => {
    if (!confirmationSuccess) {
      const observer = new IntersectionObserver(([entry]) => {
        setSticky(entry.boundingClientRect.top > 0 && !entry.isIntersecting)
      })
      if (divRef.current) observer.observe(divRef.current)
    }
  }, [confirmationSuccess])
  useEffect(() => {
    setIsMobile(!(width > 992))
  }, [width])
  const navigateToBookAppointment = () => {
    divRef.current.scrollIntoView({
      block: isMobile ? "start" : "end",
      inline: "nearest",
      behaviour: "smooth",
    })
    navigateToLeadGenerationAnalytics()
  }
  return (
    <>
      {authorData.kecFormFlag ? (
        <StickyHeader
          sticky={sticky}
          setSticky={setSticky}
          navigateToBookAppointment={navigateToBookAppointment}
          staticText={staticText}
        />
      ) : null}
      {!confirmationSuccess && (
        <div className={styles.leadGenerationLanding}>
          <div className="lead-generation" ref={divRef}>
            <div className="lead-generation__title">
              <div className="lead-generation__heading">
                {!authorData.kecFormFlag
                  ? staticText.mainTitle
                  : staticText.enquiryForm}
              </div>
              {!authorData.kecFormFlag ? (
                <span className="lead-generation__required-fields">
                  {staticText.requiredInfoText}
                </span>
              ) : (
                ""
              )}
            </div>
            <Enquiry
              staticText={staticText}
              setConfirmationSuccess={setConfirmationSuccess}
              authorData={authorData}
              stateWithCities={stateWithCities}
            />
          </div>
        </div>
      )}
      {confirmationSuccess && (
        <div ref={confirmationRef} className={styles.leadGenerationLanding}>
          <div className="lead-generation">
            <Confirmation
              staticText={staticText}
              setConfirmationSuccess={setConfirmationSuccess}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default LeadGenerationView
