import React, { useState, useRef, useEffect } from "react"
import Highlight from "@/components/core/Highlight/Highlight"
import { NumericFormat as NumberFormat } from "react-number-format"
import { getPDPUrl } from "@/utils/helper"
import useIsSsr from "@/hooks/useIsSsr"
import _isEmpty from "lodash/isEmpty"
import CONSTANTS from "@/constants/index"
import { getMoreInCollectionsInfo } from "@/components/productCard/v1/analytics"

const ProductCardView = ({
  i18n,
  product,
  currencySign,
  presetConfigs,
  swatchUrl,
  parent,
  title,
  brandName,
  bestSellerContent,
}) => {
  const { COMPONENT } = CONSTANTS
  const [productCardWidth, setProductCardWidth] = useState(0)
  const [pdpNavigationURL, setPdpNavigationURL] = useState("")
  const productCardRef = useRef()
  const presetConfigObject = presetConfigs ? JSON.parse(presetConfigs) : {}
  const newTab = false

  const isSsr = useIsSsr()

  useEffect(() => {
    setProductCardWidth(productCardRef.current.clientWidth)
  })

  useEffect(() => {
    getPDPUrl(product?.productCategory, product?.slug).then(ret =>
      setPdpNavigationURL(ret)
    )
  }, [product])

  const formatUrl = (uUrl, iWidth) => {
    if (!uUrl.includes("data:image")) {
      const sUrlPath = uUrl.split("?")[0]?.split("/")
      const sUrlId = sUrlPath[sUrlPath.length - 1]
      let sPreset = ""
      if (iWidth <= 167) {
        sPreset = presetConfigObject?.MobileLandscape ?? ""
      } else if (iWidth <= 458) {
        sPreset = presetConfigObject?.Desktop ?? ""
      } else {
        sPreset = presetConfigObject?.MobilePortrait ?? ""
      }
      if (parent === COMPONENT.PAIRS_WELL_WITH) {
        return `${swatchUrl}${presetConfigObject.PotraitCategory}?&product_src=is{PAWEB/${sUrlId}}&${sPreset}`
      }
      return `${swatchUrl}${presetConfigObject.PotraitCategory}?$product_src=is{PAWEB/${sUrlId}}&?${sPreset}`
    }
    return uUrl
  }

  const getProductName = () => {
    if (!parent && title) {
      return title
    }
    return product.name || brandName
      ? product.name || brandName
      : product.name
      ? product.name
      : product.description
  }

  const redirectPdp = (e, url) => {
    e.preventDefault()
    e.stopPropagation()
    window.location.href = url
  }

  return (
    <div ref={productCardRef} className="product-card">
      <div
        onClick={e =>
          redirectPdp(e, `${pdpNavigationURL}?skuId=${product?.selSku}`)
        }
        aria-label={`${product.name} ${currencySign + product.price} ${
          product.colors.length
        } ${i18n.ariaLabelColor}`}
        className="product-card__link gbh-data-layer"
        data-gbh-data-layer={
          !isSsr
            ? getMoreInCollectionsInfo(
                title ?? "",
                false,
                pdpNavigationURL,
                product
              )
            : ""
        }
      >
        <div className="product-card__img-container">
          <img
            src={
              // _isEmpty(presetConfigs)
              //   ? formatUrl(product.images, productCardWidth)
              product.images
            }
            className="product-card__img"
            alt=""
          />
        </div>
        <div className="product-card__details">
          <div className="product-card__left">
            <h4 className={`product-card__name${!parent ? " default" : ""}`}>
              <Highlight text={getProductName()} />
            </h4>
          </div>
          <div className="product-card__right">
            <div className="row">
              {" "}
              <b className={`product-card__price${!parent ? " default" : ""}`}>
                <NumberFormat
                  value={
                    product.discountPrice > 0
                      ? product.discountPrice
                      : product.price
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currencySign}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </b>
            </div>
            {bestSellerContent && (
              <>
                <div className="row product-card__discount-price">
                  {product.discountPrice > 0 && (
                    <p className>
                      MRP{" "}
                      <NumberFormat
                        value={product.mrpPrice ?? 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currencySign}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </p>
                  )}
                </div>
                <div className="row product-card__percent-off">
                  {product.discountPrice > 0 && (
                    <span className="colored-number">
                      {Number(product.discountpercent).toFixed()}%OFF
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="product-card__details">
          {!bestSellerContent ? (
            <div className="product-card__left">
              <p className="product-card-desc">
                {product.name || title || brandName ? product.description : ""}
              </p>
            </div>
          ) : null}
          <div className="product-card__right">
            {product.discountedPrice !== product.price &&
              product.discountedPrice > 0 && (
                <p className="product-card__discount-price">
                  <NumberFormat
                    value={product.price ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currencySign}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </p>
              )}
          </div>
        </div>
        <div className="product-card__details">
          <div className="product-card__left">
            <div className="product-card__color-text">
              {!bestSellerContent && (
                <span>
                  {product.colors.length > 1
                    ? `${product.colors.length} ${i18n.recentlyViewedColors}`
                    : `1 ${i18n.recentlyViewedColor}`}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCardView
