import { useState, useEffect } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import AuthUtilityNav from "@/components/Default/AuthUtilityNav/v1/AuthUtilityNav"

import CountryUtilNav from "@/components/Default/CountryUtilNav/v1/CountryUtilNav"
import MiniCart from "@/components/Shared/MiniCart/v1/MiniCart"
import { isElementExcluded, toggeleBodyScroll } from "@/utils/helper"
import styles from "@/components/Shared/index.module.scss"
import {
  selectAuthState,
  setAuthModalVisibility,
  setFavoritesLogin,
} from "@/store/features/authSlice"
import {
  selectFavoritesState,
  getFavorites,
} from "@/store/features/favoritesSlice"
import _get from "lodash/get"

import { useDispatch, useSelector } from "react-redux"

import utilityStyles from "@/components/Shared/UtilityWidgets/v1/UtilityWidgets.module.scss"
import { selectGenericState } from "@/store/features/genericSlice"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { getConfig } from "@/constants/config"
import { isBrandTransact } from "@/utils/index"

const UtilityNavHeader = ({ data, eventHandler }) => {
  const router = useRouter()
  const {
    store: { isOpen, mainCategoryActive, isRegionOpen, isAuthNavOpen },
    callbacks: {
      setRegionNav = () => console.log("Event Not Defined"),
      setAuthNav = () => console.log("Event Not Defined"),
    },
  } = eventHandler
  const [currentPagePath, setCurrentPagePath] = useState("/")
  const [findAStoreURL, setFindAStorURL] = useState(null)
  const [isBrandEnabled, setIsBrandEnabled] = useState(null)
  useEffect(() => {
    if (router.locale && router.pathname) {
      setCurrentPagePath("/" + router.locale + router.asPath)
    }
    const url = "/locations/find-a-store"
    setFindAStorURL(url)
    getConfig().then(config => {
      setIsBrandEnabled(_get(config, "general.isEnableBrand", ""))
    })
  }, [])
  const { winWidth } = useWindowDimensions()
  const { datalayerPageName } = useSelector(selectGenericState)
  const [findAStoreShow, setFindAStoreShow] = useState(false)
  const [findAStoreActive, setFindAStoreActive] = useState(false)
  const { isAuth } = useSelector(selectAuthState)
  const { favorites } = useSelector(selectFavoritesState)
  const [authNavShow, setAuthNavShow] = useState(false)
  const [authNavActive, setAuthNavActive] = useState(false)
  const [favoritesPage, setFavoritesPage] = useState([])
  const [countryNavShow, setCountryNavShow] = useState(false)
  const [countryNavActive, setCountryNavActive] = useState(false)

  eventHandler.callbacks.toggleFindAStore = () => {
    setFindAStoreActive(false)
  }
  useEffect(() => {
    if (findAStoreShow) {
      setFindAStoreActive(true)
    }
    if (authNavShow) {
      setAuthNavActive(true)
    }
    if (countryNavShow) {
      setCountryNavActive(true)
    }
  }, [findAStoreShow, authNavShow, countryNavShow])

  useEffect(() => {
    if (isAuth) {
      dispatch(getFavorites())
        .unwrap()
        .then(rsp => {
          if (rsp && rsp !== null) {
            setFavoritesPage(rsp.lineItems)
          } else {
            setFavoritesPage([])
          }
        })
    }
  }, [isAuth])
  useEffect(() => {
    if (favorites?.lineItems && favorites?.lineItems.length > 0) {
      setFavoritesPage(favorites?.lineItems)
    } else {
      setFavoritesPage([])
    }
  }, [favorites])

  useEffect(() => {
    if (!findAStoreActive) {
      setTimeout(() => {
        setFindAStoreShow(false)
      }, 1000)
    }
    if (!authNavActive) {
      setTimeout(() => {
        setAuthNavShow(false)
      }, 1000)
    }
    if (!countryNavActive) {
      if (winWidth <= 1200) {
        setCountryNavShow(false)
      } else {
        setTimeout(() => {
          setCountryNavShow(false)
        }, 1000)
      }
    }
  }, [findAStoreActive, authNavActive, countryNavActive])

  const closeAuth = useSelector(state => state.auth.closeAuth)
  useEffect(() => {
    if (closeAuth === true && authNavShow) {
      toggleAuthNav()
      setAuthNavShow(false)
    }
  }, [closeAuth])

  const toggleFindAStore = () => {
    if (!findAStoreShow) {
      setFindAStoreShow(true)
      toggeleBodyScroll(true)
    } else {
      setFindAStoreActive(false)
      toggeleBodyScroll(false)
    }
  }

  const toggleAuthNav = () => {
    if (!authNavShow) {
      setAuthNavShow(true)
      toggeleBodyScroll(true)
    } else {
      setAuthNavActive(false)
      toggeleBodyScroll(false)
    }
  }

  const toggleCountryNav = () => {
    if (!countryNavActive) {
      setCountryNavShow(true)
      toggeleBodyScroll(true)
    } else {
      setCountryNavActive(false)
      toggeleBodyScroll(false)
    }
  }

  const clickEvent = e => {
    if (
      !isElementExcluded(e, ["header-container", "modal-popup", "auth-modal"])
    ) {
      setAuthNavActive(false)
      setFindAStoreActive(false)
      setCountryNavActive(false)
      toggeleBodyScroll(false)
    }
  }

  useEffect(() => {
    if (findAStoreActive) {
      setAuthNavActive(false)
      setCountryNavActive(false)
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [findAStoreActive])

  useEffect(() => {
    if (authNavActive) {
      setFindAStoreActive(false)
      setCountryNavActive(false)
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [authNavActive])

  useEffect(() => {
    if (countryNavActive) {
      setAuthNavActive(false)
      setFindAStoreActive(false)
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [countryNavActive])
  const style = { display: "list-item" }
  const displayNone = { display: "none" }
  const dispatch = useDispatch()
  const onClickSignIn = e => {
    e.preventDefault()
    dispatch(setAuthModalVisibility({ show: true }))
    dispatch(setFavoritesLogin())
  }

  const onAuthMenuClick = payload => {
    setAuthNavActive(payload)
    setAuthNav(payload)
  }

  const isBrandOrTransact = isBrandTransact()

  return (
    <div className={styles.utilityNavHeaderWrapper}>
      <div className="utilitynavheader">
        <div className="gbh-utility-nav">
          <div className="gbh-utility-navbar">
            <ul role="list">
              <li
                className="gbh-nav__findStore"
                {...(isOpen &&
                !mainCategoryActive &&
                !isRegionOpen &&
                !isAuthNavOpen
                  ? { style: style }
                  : {})}
              >
                <a
                  role="button"
                  tabIndex="0"
                  aria-live="polite"
                  aria-labelledby="find-ada"
                  className={
                    "gbh-data-layer gbh-nav__findStore--link cmp-find-place"
                  }
                  href={findAStoreURL ? findAStoreURL : ""}
                  data-gbh-data-layer={`{"eventAction":"find a store","eventName":"find a store","eventType":"find a store","internalLinkName":"find a store","internalLinkPosition":"find a store","internalLinkType":"global header:navigation","internalLinkZoneName":"global header","internalLinkURL":"n/a","clickInternalLinks":"true"}`}
                >
                  Find a Store
                </a>
              </li>
              <span id="country-ada" style={displayNone}>
                Country Selector{" "}
                {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
              </span>
              <li
                className="gbh-nav__region region_arrowdropdown"
                id={
                  process.env.NEXT_PUBLIC_COUNTRYNAME.toLowerCase()
                    .split(" ")
                    .join("_") ||
                  data?.country.toLowerCase().split(" ").join("_")
                }
                {...(isOpen && !mainCategoryActive ? { style: style } : {})}
              >
                <a
                  role="button"
                  tabIndex="0"
                  aria-labelledby="country-ada"
                  aria-expanded={countryNavActive}
                  onClick={() => {
                    setRegionNav(true)
                    toggleCountryNav()
                  }}
                  className={`gbh-data-layer gbh-nav__region--link active-country ${
                    countryNavActive ? "expand" : ""
                  } `}
                  data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"n/a","internalLinkName":"${(
                    process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country
                  )?.toLowerCase()} - ${(
                    process.env.NEXT_PUBLIC_LANGUAGENAME || data?.language
                  )?.toLowerCase()}","eventName":"country select","internalLinkType":"global header : navigation","eventType":"click","eventAction":"country select"}`}
                >
                  {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
                  {process.env.NEXT_PUBLIC_LANGUAGENAME || data?.language}
                </a>

                {data[":items"]?.countryselector && (
                  <CountryUtilNav
                    show={countryNavShow}
                    countryselector={data[":items"]?.countryselector}
                    active={countryNavActive}
                    eventHandler={eventHandler}
                    toggleCountryNav={toggleCountryNav}
                    datalayerPageName={datalayerPageName}
                  />
                )}
              </li>
              {!isBrandOrTransact && (
                <li
                  className="gbh-nav__signin"
                  {...(isOpen && !mainCategoryActive && !isRegionOpen
                    ? { style: style }
                    : {})}
                >
                  <div className={utilityStyles.utilityWidgetsWrapper}>
                    <div
                      role="list"
                      className="utility-widgets utility-widgets__brand-switcher"
                    >
                      <AuthUtilityNav
                        show={authNavShow}
                        active={authNavActive}
                        toggleAuthNav={toggleAuthNav}
                        setAuthNav={setAuthNav}
                        onAuthMenuClick={onAuthMenuClick}
                      />
                      {winWidth > 1200 && (
                        <>
                          <div role="listitem" className="wishlist-widget">
                            <a
                              href={
                                isAuth
                                  ? "/my-account/my-favorites"
                                  : currentPagePath
                              }
                              aria-label="View your wishlist"
                              className="gbh-data-layer"
                              data-gbh-data-layer={`{"eventName":"wishlist icon","eventAction":"wishlist icon","internalLinkName":"wishlist","internalLinkType":"global header:wishlist","internalLinkZoneName":"global header","clickInternalLinks":"true","eventType":"global header","internalLinkURL":"n/a","internalLinkPosition":"global header wishlist","eventMsg":"n/a","eventStatus":"n/a"}`}
                              onClick={!isAuth && onClickSignIn}
                            >
                              <img
                                alt="View your wishlist"
                                aria-hidden="true"
                                role="presentation"
                                src={
                                  favoritesPage && favoritesPage.length > 0
                                    ? "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTUgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+SWNvbi9XaXNobGlzdDwvdGl0bGU+CiAgICA8ZyBpZD0iQjEtQ29tcGFyZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikljb24vV2lzaGxpc3QiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtOC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTguMTQwNjA3MTQsOS42ODg0ODE5NyBMNy41MDEsMTAuMzM0IEw2Ljg2MTU5MzY5LDkuNjg4NDgxOTcgQzUuMjkyNDIzNTIsOC4xMDM4MzkzNCAyLjc0NjI0NDc5LDguMTAzODM5MzQgMS4xNzcwNzQ2Myw5LjY4ODQ4MTk3IEMtMC4zNDg3ODE5NTgsMTEuMjI5MzgzOSAtMC4zOTYyNTE5NTksMTMuNzE4NTgzNCAxLjA2NDMyMjM2LDE1LjI5NzA0NDIgTDEuMTcwNzE4NTIsMTUuNDA4NTcwNyBMNy41MDExMTM0NCwyMS44MDE1MDMzIEwxMy44MDg0ODM5LDE1LjQzMjE5OTggTDEzLjkxNTE4NzgsMTUuMzIxMTQwOCBDMTUuMzk1NTk2OCwxMy43MjE3MTI1IDE1LjM1Mzk3MDMsMTEuMjMyNDAxIDEzLjgyNTEyNjIsOS42ODg0ODE5NyBDMTIuMjU1OTU2LDguMTAzODM5MzQgOS43MDk3NzczMSw4LjEwMzgzOTM0IDguMTQwNjA3MTQsOS42ODg0ODE5NyBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                                    : "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE1cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE1IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDx0aXRsZT5JY29uL1dpc2hsaXN0PC90aXRsZT4NCiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJCdXR0b24vSW1hZ2UtSWNvbnMvV2lzaGxpc3QtRW1wdHkvRGFyay1HcmF5LUJHIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtMTEuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+DQogICAgICAgICAgICA8ZyBpZD0iSWNvbi9XaXNobGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCAzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik04LjE0MDYwNzE0LDkuNjg4NDgxOTcgTDcuNTAxLDEwLjMzNCBMNi44NjE1OTM2OSw5LjY4ODQ4MTk3IEM1LjI5MjQyMzUyLDguMTAzODM5MzQgMi43NDYyNDQ3OSw4LjEwMzgzOTM0IDEuMTc3MDc0NjMsOS42ODg0ODE5NyBDLTAuMzQ4NzgxOTU4LDExLjIyOTM4MzkgLTAuMzk2MjUxOTU5LDEzLjcxODU4MzQgMS4wNjQzMjIzNiwxNS4yOTcwNDQyIEwxLjE3MDcxODUyLDE1LjQwODU3MDcgTDcuNTAxMTEzNDQsMjEuODAxNTAzMyBMMTMuODA4NDgzOSwxNS40MzIxOTk4IEwxMy45MTUxODc4LDE1LjMyMTE0MDggQzE1LjM5NTU5NjgsMTMuNzIxNzEyNSAxNS4zNTM5NzAzLDExLjIzMjQwMSAxMy44MjUxMjYyLDkuNjg4NDgxOTcgQzEyLjI1NTk1Niw4LjEwMzgzOTM0IDkuNzA5Nzc3MzEsOC4xMDM4MzkzNCA4LjE0MDYwNzE0LDkuNjg4NDgxOTcgWiBNMTMuMTE0NTU4OSwxMC4zOTIxMTEzIEMxNC4yNjY2NjkyLDExLjU1NTU4MTYgMTQuMjk4MTAyMiwxMy40MzUzMTE1IDEzLjE4NzU0NTEsMTQuNjM1MjQ1MyBMMTMuMDkyNTIwMiwxNC43MzQxMDUzIEw3LjUwMSwyMC4zOCBMMS44ODc2NDE4OSwxNC43MTE0NzgzIEwxLjc5Mjk1NDA5LDE0LjYxMjE4MzEgQzAuNzAxOTMzNDQxLDEzLjQzMzAxMzYgMC43Mzc3ODAxMDEsMTEuNTUzMzExIDEuODg3NjQxODksMTAuMzkyMTExMyBDMy4wNjU1MDk0NSw5LjIwMjYyOTU4IDQuOTczMTU4ODcsOS4yMDI2Mjk1OCA2LjE1MTAyNjQyLDEwLjM5MjExMTMgTDcuNTAxMTAwNDIsMTEuNzU1NDk3NCBMOC44NTExNzQ0MSwxMC4zOTIxMTEzIEMxMC4wMjkwNDIsOS4yMDI2Mjk1OCAxMS45MzY2OTE0LDkuMjAyNjI5NTggMTMuMTE0NTU4OSwxMC4zOTIxMTEzIFoiIGlkPSJQYXRoIj48L3BhdGg+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4="
                                }
                              />
                            </a>
                          </div>

                          <div
                            role="listitem"
                            className="cart-indicator-widget"
                          >
                            <MiniCart
                              eventHandler={eventHandler}
                              source={"from desk"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UtilityNavHeader
