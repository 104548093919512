/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import _isEmpty from "lodash/isEmpty"

import useIsSsr from "@/hooks/useIsSsr"
import {
  convertStringToBoolean,
  createIdFromString,
  getRandom,
  getShortenedUrl,
  sanitizeInnerHtml,
} from "@/utils/helper"
import { getConfig } from "@/constants/config"
import { ConditionalWrapper } from "@/utils"
import DynamicImage from "@/components/core/Image/DynamicImage"
import Description from "@/components/Default/Teaser/v1/Description"
import Action from "@/components/Default/Teaser/v1/Action"
import {
  COMPONENT_TYPES,
  EMPTY_PIXEL,
  TEASER_CAPTION_CLASSES_LIST,
  TEASER_CLASSES,
  DOMAIN,
} from "@/constants"
import ImagePlaceHolder from "@/components/core/Image/ImagePlaceHolder"
import Utilities from "@/components/Default/Teaser/v1/Utilities"
import { selectGenericState } from "@/store/features/genericSlice"
import { getTitleEventInfo } from "@/components/Default/Teaser/v1/analytics"

const Teaser = props => {
  const [dynamicId, setDynamicId] = useState("")
  const { pageIsInteractive } = useSelector(selectGenericState)
  const {
    data: {
      id = "",
      appliedCssClassNamesCustom = "",
      linkURLRef = false,
      linkNewTab = false,
      linkAll = false,
      linkTitle = false,
      linkImage = false,
      title = "",
      description = "",
      enableFav = false,
      enableShare = false,
      mainTitle = "",
      componentPosition = "",
      pageType = "",
      linkDescription = "",
      linkURL = "",
      caption = "",
      datalayerLinkName = "",
      componentLinkType = "",
      eventName = "",
      eventAction = "",
      actions = [],
      downLoadLink = null,
      imageResource: {
        imageServerUrl = "",
        assetID = "",
        pretitle: preTitle = "",
        urlModifiers = "",
        alt = "",
      } = {},
      utilities = [],
      utilitiesEnabled = false,
      dataLayerLinkType = "",
    } = {},
    fullData = {},
    showEmptyPlaceholder = false,
    maxHeight = "",
    containerId = "",
  } = props

  const [parentActionLink, setParentActionLink] = useState(linkURL)
  const [componentGroup, setComponentGroup] = useState([])
  const [appliedCssClassNamesCustoms, setAppliedCssClassNamesCustoms] =
    useState([])
  const [columnClassNames, setColumnClassNames] = useState("")
  const [general, setGeneral] = useState(null)
  const [innerCaption, setInnerCaption] = useState(false)
  const [isInsideImage, setIsInsideImage] = useState(false)
  const [activeIndex, setActive] = useState(0)

  const isSsr = useIsSsr()
  const isCaptionOnly = Boolean(
    title ||
      description ||
      actions?.length ||
      preTitle ||
      assetID ||
      showEmptyPlaceholder
  )

  useEffect(() => {
    getConfig().then(config => {
      setGeneral(config?.general)
    })
  }, [])
  useEffect(() => {
    if (assetID) {
      const dynamicId = createIdFromString(assetID)
      setDynamicId(dynamicId)
    } else {
      const dynamicId = createIdFromString(getRandom() + "")
      setDynamicId(dynamicId)
    }
  }, [assetID])
  useEffect(() => {
    if (linkURL) {
      getShortenedUrl(linkURL).then(res => {
        setParentActionLink(res)
      })
    }
  }, [linkURL])

  useEffect(() => {
    if (!_isEmpty(fullData)) {
      Object.keys(fullData).forEach(key => {
        setAppliedCssClassNamesCustoms(appliedCssClassNamesCustom => [
          ...appliedCssClassNamesCustom,
          fullData[key]["appliedCssClassNamesCustom"],
        ])
        setComponentGroup(cmpGroup => [...cmpGroup, fullData[key][":type"]])
      })
    }
  }, [fullData])

  useEffect(() => {
    let classNameCustom = ""
    if (componentGroup.length > 0) {
      if (
        componentGroup.findIndex(a => a?.includes(COMPONENT_TYPES.TEASER)) >
          -1 &&
        appliedCssClassNamesCustoms.findIndex(a =>
          a?.includes("hero-banner-article-top")
        ) > -1 &&
        appliedCssClassNamesCustoms.findIndex(a =>
          a?.includes("hero-banner-article-bottom")
        ) === -1
      ) {
        if (!columnClassNames?.includes("hero-banner-top-only")) {
          classNameCustom += " hero-banner-top-only "
        }
        if (
          !columnClassNames?.includes("paddingbasedbreadcrum") &&
          componentGroup.findIndex(a =>
            a?.includes(COMPONENT_TYPES.BREADCRUMB)
          ) > -1
        ) {
          classNameCustom += " paddingbasedbreadcrum "
        }
        if (
          !columnClassNames?.includes("without-breadcrumb") &&
          componentGroup.findIndex(a =>
            a?.includes(COMPONENT_TYPES.BREADCRUMB)
          ) === -1
        ) {
          classNameCustom += " without-breadcrumb "
        }
      }
    }
    setColumnClassNames(className => (className += classNameCustom))
  }, [componentGroup])

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      if (
        TEASER_CAPTION_CLASSES_LIST.some(classNames =>
          classNames
            .split(" ")
            .every(className =>
              appliedCssClassNamesCustom?.trim()?.includes(className)
            )
        )
      ) {
        setInnerCaption(true)
      }
    }
  }, [isSsr, pageIsInteractive])

  useEffect(() => {
    if (appliedCssClassNamesCustom) {
      checkUtilityPosition()
    }
  }, [appliedCssClassNamesCustom])

  const checkUtilityPosition = () => {
    let isInsideImage = false
    TEASER_CLASSES.forEach(item => {
      isInsideImage = appliedCssClassNamesCustom.includes(item)
      if (isInsideImage) {
        setIsInsideImage(isInsideImage)
        return
      }
    })
  }

  const getDataLayerLinkType = () => {
    if (mainTitle || title) {
      return `${pageType}:${mainTitle || title}`
    }
    return dataLayerLinkType
  }
  let finalEventString = ""
  let mid = ""
  let end = ""
  let combinedZoneName = ""
  let instagramTitle = "follow us on instagram"
  if (eventName) {
    mid =
      eventName === "image cards"
        ? "follow us on instagram"
        : eventAction || datalayerLinkName
        ? "main content area"
        : "zone"
    end =
      eventAction.replace(/;/g, "") ||
      datalayerLinkName.replace(/;/g, "") ||
      "rm"
    if (eventName === "register your kohler products") {
      mid = eventName
      combinedZoneName = eventName
    }
    if (eventName !== "register your kohler products") {
      finalEventString = `${mid}:${end}`
      combinedZoneName =
        eventName === "image cards"
          ? "follow us on instagram"
          : "main content area"
    } else {
      finalEventString = `${mid}:learn more`
    }
  } else finalEventString = `zone:${mainTitle.toLowerCase() || instagramTitle}`
  return (
    <div
      className={`teaser ${
        appliedCssClassNamesCustom ? appliedCssClassNamesCustom : ""
      }${columnClassNames}`}
    >
      {isCaptionOnly ? (
        <div
          className="cmp-teaser"
          data-gbh-data-layer={`{"clickInternalLinks":"${
            linkNewTab ? "false" : "true"
          }",
          "internalLinkPosition":"${pageType?.toLowerCase()}:${
            componentPosition || "teaser"
          }",
          "internalLinkZoneName":"${pageType?.toLowerCase()}:${combinedZoneName}",
          "internalLinkURL":"${linkURL}","internalLinkName":"${
            eventName === "image cards"
              ? assetID.substring(assetID.indexOf("/") + 1)
              : datalayerLinkName
          }",
          "eventName":"${pageType?.toLowerCase()}:${finalEventString}"
          ,"internalLinkType":"${pageType}:${
            componentLinkType || mainTitle
          }","eventType":"teaser",
          "eventAction":"${pageType?.toLowerCase()}:${finalEventString}"}`}
        >
          <ConditionalWrapper
            condition={linkAll}
            wrapper={children => (
              <a
                href={parentActionLink}
                target={linkNewTab ? "_blank" : "_self"}
                data-gbh-clickable="true"
                rel="noreferrer"
              >
                {children}
              </a>
            )}
          >
            <div className="cq-dd-image cmp-teaser__image">
              <div className="cmp-image">
                <div className="s7responsiveContainer cmp-teaser__image cmp-image__image">
                  <ConditionalWrapper
                    condition={linkImage}
                    wrapper={children => (
                      <a
                        href={parentActionLink}
                        target={linkNewTab ? "_blank" : "_self"}
                        data-gbh-clickable="true"
                        rel="noreferrer"
                      >
                        {children}
                      </a>
                    )}
                  >
                    {!isSsr ? (
                      assetID || showEmptyPlaceholder ? (
                        <DynamicImage
                          style={
                            maxHeight && showEmptyPlaceholder && !assetID
                              ? { maxHeight }
                              : {}
                          }
                          className="fluidimage cmp-image__image"
                          imgUrl={`${
                            assetID
                              ? `${imageServerUrl}${assetID}`
                              : EMPTY_PIXEL
                          }`}
                          alt={caption}
                          itemProp="contentUrl"
                          uniqueId={dynamicId}
                          urlModifiers={urlModifiers}
                          fetchPriority={
                            appliedCssClassNamesCustom.includes("hero-banner")
                              ? "high"
                              : "auto"
                          }
                        />
                      ) : (
                        <ImagePlaceHolder assetID={assetID} />
                      )
                    ) : null}
                  </ConditionalWrapper>
                </div>
              </div>
              {isInsideImage && utilitiesEnabled && utilities?.length ? (
                <Utilities
                  utilities={utilities}
                  title={title}
                  assetID={assetID}
                  imageServerUrl={imageServerUrl}
                  alt={alt}
                  pageType={pageType}
                  dataLayerLinkType={getDataLayerLinkType()}
                  mainTitle={mainTitle}
                  downLoadLink={downLoadLink}
                  containerId={containerId}
                />
              ) : null}
              {innerCaption && caption ? (
                <div className="cmp-img-caption">
                  {sanitizeInnerHtml(caption)}
                </div>
              ) : null}
            </div>

            {title ||
            description ||
            enableFav ||
            enableShare ||
            (actions && actions.length) ||
            preTitle ? (
              <div className="cmp-teaser__content">
                {preTitle ? (
                  <div className="cmp-teaser__pretitle">
                    {sanitizeInnerHtml(preTitle)}
                  </div>
                ) : null}
                {title ? (
                  <div className="cmp-teaser__title" id={id}>
                    <ConditionalWrapper
                      condition={linkTitle}
                      wrapper={children => (
                        <a
                          href={linkURL}
                          rel={linkURLRef ? "noreferrer" : ""}
                          target={linkNewTab ? "_blank" : "_self"}
                          data-gbh-data-layer={getTitleEventInfo(
                            linkNewTab,
                            componentPosition,
                            linkURL,
                            mainTitle,
                            pageType,
                            componentLinkType
                          )}
                          data-gbh-clickable="true"
                        >
                          {children}
                        </a>
                      )}
                    >
                      {sanitizeInnerHtml(title)}
                    </ConditionalWrapper>
                  </div>
                ) : null}
                {description ? (
                  <Description
                    linkURLRef={linkURLRef}
                    general={general}
                    linkNewTab={linkNewTab}
                    description={description}
                    mainTitle={mainTitle}
                    componentPosition={componentPosition}
                    pageType={pageType}
                    linkDescription={linkDescription}
                  />
                ) : null}
                {actions && actions?.length ? (
                  <div className="cmp-teaser__action-container">
                    {actions.map((action, index) => {
                      const {
                        playVideoCTA = false,
                        primaryCTA = false,
                        secondaryCTA = false,
                        url = "",
                        title = "",
                        newTab = "",
                        urlRef = "",
                      } = action

                      return (
                        <Action
                          key={`teaser-action-${index + 1}-${getRandom()}`}
                          playVideoCTA={convertStringToBoolean(playVideoCTA)}
                          primaryCTA={convertStringToBoolean(primaryCTA)}
                          secondaryCTA={convertStringToBoolean(secondaryCTA)}
                          link={url}
                          text={title}
                          newTab={convertStringToBoolean(newTab)}
                          urlRef={urlRef}
                          id={id}
                          dataLayerLinkType={dataLayerLinkType}
                          pageType={pageType}
                          linkAll={convertStringToBoolean(linkAll)}
                          mainTitle={mainTitle}
                          index={index}
                          activeIndex={activeIndex}
                          setActive={setActive}
                          eventAction={eventAction}
                          eventName={eventName}
                        />
                      )
                    })}
                  </div>
                ) : null}
                {!isInsideImage && utilitiesEnabled && utilities?.length ? (
                  <Utilities
                    utilities={utilities}
                    title={title}
                    assetID={assetID}
                    imageServerUrl={imageServerUrl}
                    alt={alt}
                    pageType={pageType}
                    dataLayerLinkType={getDataLayerLinkType()}
                    mainTitle={mainTitle}
                    downLoadLink={downLoadLink}
                    containerId={containerId}
                  />
                ) : null}
              </div>
            ) : null}
          </ConditionalWrapper>
        </div>
      ) : null}
      {!innerCaption && caption ? (
        <div className="cmp-img-caption">{sanitizeInnerHtml(caption)}</div>
      ) : null}
    </div>
  )
}

export default Teaser
